<template>
  <div style="height:100%">
    <div class="backgoods" :class="scrolls == 1500 ? 'salce' : ''">
<div class="outSanWei">
          <div class="titlebiao">
            <div class="Logolist">
              <img src="../assets/exhibition/ditudaolan.png" class="logo" alt="">
              <img src="../assets/exhibition/3Dmap.png" class="logoTitle" alt="">
            </div>
          </div>
          <div class="One" @click="actives('1')" :class="active==1 ? 'beijingOne bai' : ''">
              <div class="OneLeft">
                  1F
              </div>
              <div class="OneRight">
                  <div class="Onesmall">
                      A&nbsp;&nbsp;精品展厅 <br>
                      <span class="oldsmall">
                        &nbsp;&nbsp;&nbsp;&nbsp;Boutique&nbsp;Exhibition&nbsp;Hall
                      </span>
                  </div>
                  <div class="Onesmall">
                      B&nbsp;&nbsp;临展厅 <br>
                      <span class="oldsmall">
                        &nbsp;&nbsp;&nbsp;&nbsp;Temporary&nbsp;Exhibition&nbsp;Hall
                      </span>
                  </div>
              </div>
          </div>
          <div class="border"  :class="active==1 ? 'hidn' : ''"></div>
          <div class="two" @click="actives('2')" :class="active==2 ? 'beijingTwo white' : ''">
              <div class="TwoLeft">
                  2F
              </div>
              <div class="twoRight">
                  <div class="Onesmall">
                      C&nbsp;&nbsp;历史文化一展厅
                      <span class="oldsmall">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                       Shunyi History & Culture Exhibition Hall 1 

                      </span>
                  </div>
                  <div class="Onesmall">
                      D&nbsp;&nbsp;历史文化二展厅
                      <span class="oldsmall">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                Shunyi History & Culture Exhibition Hall 2

                      </span>
                  </div>
                  <div class="Onesmall">
                      E&nbsp;&nbsp;历史文化三展厅
                      <span class="oldsmall">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                Shunyi History & Culture Exhibition Hall 3

                      </span>
                  </div>
              </div>
          </div>
          <div class="border" :class="active==2 ? 'hidn' : ''"></div>
          <div class="sancis" @click="actives('3')" :class="active==3 ? 'beijingThree white' : ''">
              <div class="threeLeft" :class="active==3 ? 'bai' : ''">
                  3F
              </div>
              <div class="twoRight">
                  <div class="Onesmall" :class="active==3 ? 'bai' : ''">
                      F&nbsp;&nbsp;多媒体功能厅
                      <span class="oldsmall" :class="active==3 ? 'bai' : ''">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Multimedia&nbsp;Hall
                      </span>
                  </div>
              </div>
          </div>
      </div>
    <div class="tit shuzi_all">
      <div class="biao" v-show="active == 1">1F
        
      </div>
      <div class="jing" v-show="active == 1">
        A精品展厅 &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;B临展厅
      </div>
      <div class="biao" v-show="active == 2">2F</div>
      <div class="jing" v-show="active == 2">
        C 历史文化一展厅 <br />
        D 历史文化二展厅 <br />
        E 历史文化三展厅
      </div>
      <div class="biao" v-show="active == 3">3F</div>
      <div class="jing" v-show="active == 3">F 多媒体功能厅</div>
      
      <a class="quan" href="http://www.symuseum.cn/pano/
" target='_blank' >  博物馆全景
</a>
       <div class="map">
        <img src="../assets/zhanting2.png" v-if="active == 1" alt="" />
        <img src="../assets/exhibition/2.png" v-if="active == 2" alt="" />
        <img src="../assets/exhibition/3.png" v-if="active == 3" alt="" />
      </div>
        <img class="difooterImg" src="../assets/digitalPavilion.png">
      <div class="difooter">
      </div>
      <!-- <div class="list">
          <img src="../assets/exhibition/listImg.png" alt="">
      </div>
      <div class="FooterImg">
          <img src="../assets/exhibition/vectorSmart.png" alt="" />
      </div> -->
      </div> 
      
    </div>
  </div>
</template>

<script>
import { fetch } from '../api/https';
export default {
  data() {
    return {
      scrolls:Number,
      active: 1
    };
  },
  created() {},
  mounted() {
    fetch("/getMenu").then((res) => {
        document.title = res.data[3].menu_seo_title;

      });
  },
  methods: {
    actives(val){
      this.active = val
    }
  },
};
</script>

<style scoped>
.backgoods{
  height: 100%; 
  width: 100%;
  background: url("../assets/exhibition/juxing510.png");
  display: flex;
  /* min-width: 1680px; */
  position: relative;
  min-height: 850px;
}
.tit {
  padding-top: 81px;
  /* padding-left:157px; */
  box-sizing: border-box;
  float:left;
}
.biao {
height: 53px;
font-size: 72px;
font-family: PingFang SC;
font-weight: bold;
color: #BF9860;
line-height: 60px;
margin-bottom: 32px;
}
.shuzi_all {
  box-sizing: border-box;
  /* margin-top: 100px; */
   height: 100%; 
  /* width: 100%; */
  /* background: url("../assets/exhibition/juxing510.png"); */
  float:left;
  position:relative;
  padding-bottom:30px;
  margin: 0 auto;
  margin-left: 157px;
}
.outSanWei{
  width: 292px;
  height: 458px;
  background: #FFFFFF;
  border-radius: 5px;
  position: absolute;
  /* left: 1459px; */
  top: 88px;
  right: 169px;
  z-index: 1000;
  font-family: PingFang-SC-Medium;
}

.map {
  width: 992px;
  height: 570px;
  position: absolute;
  top: 40px;
  left: 237px;
}
.jing {
  color: #bf9860;
  font-size: 24px;
  line-height: 36px;
  font-family: PingFang-SC-Medium;
  position: absolute;
  font-weight: 500;
}
.map img{
    width: 992px;
  height: 570px;
}
.quan {
  display: block;
  height: 173px;
  width: 230px;
  position: absolute;
  top: 410px;
  left: 6px;
  text-align: center;
  line-height: 131px;
  font-size: 28px;
  color: white;
  background: url("../assets/bowuguan.png") no-repeat;
  padding-top: 20px;
  box-sizing: border-box;
}
.list {
  width: 1500px;
  height: 135px;
  box-sizing: border-box;
  /* height: 80px; */
  margin: 0 auto;
  display: flex;
  /* flex-direction: column; */
  align-content: center;
  justify-content: space-between;
  position: absolute;
  bottom: 115px;
  left: 210px;
}
.difooter{
    max-width: 1527px;
    min-width: 1350px;
    /* padding-left:53px; */
    /* height: 175px; */
    /* padding-top: 477px; */
    /* padding-bottom: 42px; */
    /* position: absolute;
    bottom: 42px; */
    margin: 0 auto;
   
}
.difooter img{
  max-width: 1527px;
    height: 219px;
  width: 100%;
  padding-top: 420px;
}
.difooterImg{
  position: absolute;
    min-width: 1300px;
  top:600px;
}
.FooterImg{
  width: 1263px;
  height: 33px;
  position:absolute;
  bottom: 30px;
  left: 329px;
}
.FooterImg img{
  height: 100%;
  width: 100%;

}
.list img{
  width: 100%;
  height: 100%;
}
.li {
  width: 80%;
  margin:0 auto;
  height: 130px;
  text-align: center;
  color: #666666;
  font-family:PingFang-SC-Medium;
  font-size: 18px;
}
.li span {
  font-size: 9px;
}
.li img {
  width: 88px;
  height: 88px;
}
.daolanMap {
  position:absolute;
  top:10%;
  right:4%;
  box-sizing: border-box;
  height: 458px;
  width: 292px;
  float:left;
  background-color: white;
}
.head img {
  margin: 0px;
  margin-right: 5px;
}
.SpanTitle{
  float:left;

}
.SpanTitle img{
    height:23px;
  width:165px;
  margin-top:4px;
  margin-left:2px;
}
.head {
  
  height: 90px;
  font-size: 27px;
  font-family: PangMenZhengDao;
  font-weight: 400;
  color: #bf9860;
  padding: 20px 40px;
  text-align:center;
  padding-bottom:10px;  
  margin-bottom:10px;
}
.heaeder{
  height:40px;
}
.heaeder img{
  float:left;
}
.border{
  width: 251px;
  height: 1px;
  border-top: 1px dashed #E1E6F0;
  width: 250px;
  margin: 0 auto;
  padding-bottom: 5px;
}
.left {
  width: 15%;
  float: left;
  text-align: center;
  font-size: 22px;
  display:flex;
  justify-content:center;
  align-content: center;
  height:100%;
  box-sizing:border-box;
  padding-top:20px;
}

.right {
  text-align: left;
  width:85%;
  float: right;
  font-size: 15px;
  line-height:18px;
  color: #bf9860; 
   display:flex;
  align-content: center;
  flex-direction:column;
  height:100%;
  box-sizing:border-box;
  padding-left:5%;
}
.right span {
  white-space: nowrap; 
  font-size: 12px;
  display:block;
  width:100%;
  float:left;
  transform: scale(0.7); 
  height:20px;
  margin-left:-10px;
}
.back {
  background: #bf9860;
  background-size: contain;
  border-radius: 10px;
}
.backs {
  background: #bf9860;
  background-size: contain;
  border-radius: 10px;
}
.Ff {
  color: white;
}

.footer {
  /* height: 85px; */
  height: 80px;
  width: 100%;
}
.footer img {
  width: 100%;
  margin: 0 auto;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left:-10px;
  user-select: none;
}

.titlebiao{
  width: 100%;
  padding-top: 40px;
  padding-bottom: 77px;
}
.logo{
  width: 29px;
  height: 29px;
  margin-right: 12px;
  float: left;
}
.logoTitle{
  height: 23px;
  width: 165px;
  margin-top: 3px;
  float: left;
}
.Logolist{
  margin: 0 auto;
  width: 212px;
}
.One{
  color: #bf9860;
  height: 105px;
  width: 308px;
  margin-left: -9px;
  display: flex;
  overflow: hidden;
  justify-content: space-around;
}
.OneLeft{
  padding-left: 30px;
  height: 22px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  /* line-height: ; */
  padding-top: 17px;
  height: 84px;
  box-sizing: border-box;
  
}
.OneRight{
  height: 84px;
  width:180px;
}
.twoRight{
    width:180px;
    overflow: hidden;
}
.Onesmall{
  height: 46px;
font-size: 18px;
font-family: PingFang SC;
font-weight: 500;
}
.oldsmall{
  font-size: 12px;
  transform: scale(0.7);
  text-align: left;
  white-space: nowrap; 
  display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
.two{
  height: 154px;
  color: #bf9860;
  width: 308px;
  margin-left: -9px;
  display: flex;
  overflow: hidden;
  justify-content: space-around;
}
.TwoLeft{
  padding-left: 30px;
  height: 154px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  /* line-height: ; */
  padding-top:45px;
  box-sizing: border-box;
}
.sancis{
  width: 308px;
  color: #bf9860;
  height: 81px;
  margin-left: -9px;
  padding-top: 5px;
    display: flex;
  overflow: hidden;
  justify-content: space-around;
}
.threeLeft{
  /* padding-top: 5px; */
  height: 30px;
  font-size: 30px;
  padding-left: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  box-sizing: border-box;
}
>>>.beijingOne{
  background: url('../assets/exhibition/Ones.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 20px;
  margin-top: -20px;
  color: white;
  /* box-sizing: border-box; */
}
>>>.beijingTwo{
  background: url('../assets/exhibition/twos.png');
  background-size: 100% 100%;
  /* box-sizing: border-box; */
  padding-top: 10px;
  margin-top: -10px;
  color: white;
}
>>>.beijingThree{
  background: url('../assets/exhibition/threes.png') no-repeat;
  background-size:100% 84% ;
  padding-top: 15px;
  margin-top: -10px;
  /* padding-bottom: 10px; */
  color: white;
  /* box-sizing: border-box; */
}
.hidn{
  opacity: 0;
}
.bai{
}
</style>

<style>
@media screen and (min-width: 1500px)and (max-width: 1680px) {
.backgoods{
     height: 100%; 
  background: url("../assets/exhibition/juxing510.png");
  
}
.shuzi_all {
  box-sizing: border-box;
  /* margin-top: 100px; */
   height: 100%; 
  /* width: 100%; */
  /* background: url("../assets/exhibition/juxing510.png"); */
  float:left;
  position:relative;
  padding-bottom:30px;
  margin: 0 auto;
  margin-left: 80px !important;
}
.outSanWei{
  width: 292px;
  height: 458px;
  background: #FFFFFF;
  border-radius: 5px;
  position: absolute;
  /* left: 1459px; */
  top: 88px;
  right: 40px !important;
  z-index: 1000;
}

.map {
  width: 992px;
  height: 570px;
  position: absolute;
  top: 40px;
  left: 237px;
}
.difooterImg{
  /* transform: scale(100%-20px); */
  width: 1340px;
  left: 60px !important;
}
.outSanWei{
  right: 75px;
}
.map img{
    width: 899px;
    height: 504px;
    margin-top: 50px;
}
}
@media screen and (max-width: 1499px) and (min-width: 1367px) {
  .shuzi_all{
  margin-left: 50px !important;
}
.outSanWei{
  right: 50px !important; 
}
.difooterImg{
  /* transform: scale(100%-20px); */
     width: 80%  !important;
}
.map{ 
      top: 80px !important;
    left: 150px !important;
    
}
.map img{
      width: 850px !important;
    margin-top: 20px !important;
    height: 510px !important;
    margin-left: 50px;
}
}
@media screen and (max-width: 1366px) {
.backgoods{
     height: 100%; 
  background: url("../assets/exhibition/juxing510.png");
  
}
.shuzi_all{
  margin:  0 auto !important;
}
.difooterImg{
  /* transform: scale(100%-20px); */
  width:1300px !important;
}
.outSanWei[data-v-6ce1d0ee]{
  right: 20px !important;
}
.map img{
      width: 790px !important;
    height: 510px !important;
    margin-top: 50px !important;
}
.biao{
  margin-left: 15px !important;
}
.jing{
  margin-left: 16px !important;
}
}
</style>
